
export const thisIsDevEnvironment = (process.env.NODE_ENV === 'development');
export const baseUrl = thisIsDevEnvironment ? "http://localhost:5000": "";
export const apiPrefix = "/_api/"

export const loadScript = (url: string) => {
    return new Promise<void>((resolve, reject) => {
        const existingScriptEl = document.querySelectorAll(`script[src='${url}']`);
        if(existingScriptEl.length > 0)
        {
            resolve();
        }
        else
        {
            var script = document.createElement("script") as any;
            script.type = "text/javascript";
            if (script.readyState) {  //IE
                script.onreadystatechange = function () {
                    if (script.readyState === "loaded" ||
                        script.readyState === "complete") {
                        script.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {  //Others
                script.onload = function () { 
                    resolve();
                };
            }
            script.onerror = function () {
                reject();
            };
            script.src = url;
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    });
};


export const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }
