import { thisIsDevEnvironment } from "../lib/helper";

const ensureStartsWithSlash = (text: string): string => {
    return text.startsWith("/") ? text : `/${text}`;
}

export const makeApiCall = (path: string, method?: string, body?: BodyInit, additionalHeaders?: HeadersInit, noHeader?: boolean): Promise<any> => {
    path = ensureStartsWithSlash(path);
    const apiPath = thisIsDevEnvironment ? `http://localhost:5000${path}` : path;
    let reqHeaders: HeadersInit | undefined = noHeader ? {} : { "Content-Type": "application/json" };
    reqHeaders = { ...reqHeaders, ...additionalHeaders };
    return new Promise(async (resolve, reject) =>
        fetch(apiPath, { "method": method, headers: reqHeaders, credentials: "include", body: body }).then(async (r) => {
            if (!r.ok) {
                if (r.status === 401) {
                    window.location.assign(`/session-expired?redirect=${window.location.pathname}`);
                    reject(401)
                }
                else {
                    try {
                        const text = await r.text();
                        reject(text);
                    }
                    catch (err) {
                        const json = await r.json();
                        reject(json);
                    }
                }
            }
            else {
                const redirectUrl = r.headers.get("cpp_redirect");
                if (redirectUrl) {
                    window.location.assign(redirectUrl);
                }
                else if (r.headers.get("content-type") === 'application/octet-stream') {
                    resolve(await r.blob())
                }
                else if (r.statusText.toLowerCase() === "no content") {
                    resolve({});
                }
                else {
                    r.json().then(json => {
                        if (json.redirectTo) {
                            window.location.assign(json.redirectTo);
                        }
                        else {
                            resolve(json);
                        }
                    })
                }
            }
        }).catch(err => {
            reject(err);
        }));
}