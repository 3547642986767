import { Icon } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import { useNavContext } from '../providers/navProvider';

const KNOWN_CATEGORIES = ['PRODUCTS', 'TOOLS', 'SERVICES & SUPPORT', 'PARTNERS'];
type supportLink = {
    title: string;
    link: string;
    trimmed?: boolean; /* This property will be updated dynamically */
}
const supportLinks: supportLink[] = [
    {
        title: 'Frequently Asked Questions',
        link: '/faq',
        trimmed: false
    },
    {
        title: 'Frequently Used Links',
        link: '/frequentlyusedlinks',
        trimmed: false
    },
    {
        title: 'Parts and FRUs',
        link: '/partsFRU',
        trimmed: true
    },
    {
        title: 'Research',
        link: '/research',
        trimmed: true
    },
    {
        title: 'Secure File Transfer',
        link: '/sftp',
        trimmed: true
    },
    {
        title: 'Training Videos',
        link: '/trainingvideos',
        trimmed: true
    },
    {
        title: 'White Papers',
        link: '/whitepapers',
        trimmed: false
    }
]

interface IProductLine {
    Title: string;
}

export interface INavigationItem {
    Title: string;
    ProductCategory: string;
    ProductLine?: IProductLine;
}

export const NavSections: React.FunctionComponent<{ products: INavigationItem[], titleFieldName: string, urlPrefix: string, addSupportLinks?: boolean }> = ({ products, titleFieldName, urlPrefix, addSupportLinks }) => {
    const navContext = useNavContext();
    const mapProducts = products.map(function (o) {
        return {
            ...o, Title: o.ProductCategory === 'Products' || o.ProductCategory === 'Tools' ? o.ProductLine.Title : o.Title
        }
    }).reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.Title === current.Title)) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);

    const getNav = (category: string): JSX.Element => {
        const filteredProducts = [...mapProducts, ...supportLinks.filter((val) => !val.trimmed).map(function (obj) {
            return { Title: obj.title, ProductCategory: 'Services & Support' }
        }
        )].sort((a, b) => (a.Title < b.Title) ? -1 : 1).filter(p => p.ProductCategory === category);
        return <div key={`top-nav-${category}`} className='dropdown'>
            <NavLink to='#0' className={`dropbtn ${navContext.ActiveNode === category ? 'activeTopLink' : 'topLink'}`} >{category.toUpperCase()}
                <Icon iconName='ChevronDownSmall' className='topNavChevronDown' />
            </NavLink >
            <div className='dropdown-content'>
                {filteredProducts.map((p, key) => {
                    return (
                        <NavLink
                            key={key}
                            onClick={() => {
                                navContext.SetActiveNode(category);
                                navContext.SetResponsive('');
                            }}
                            to={category === 'Services & Support' && supportLinks.find(node => node.title === decodeURIComponent(p[titleFieldName])) ? `${supportLinks.find(node => node.title === decodeURIComponent(p[titleFieldName]))?.link}` : `/${urlPrefix}/` + encodeURIComponent(p[titleFieldName])}>{p[titleFieldName]}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    }

    const productCategories = mapProducts.map(p => p.ProductCategory).filter((v, i, a) => a.indexOf(v) === i).filter(c => c && KNOWN_CATEGORIES.includes(c.toUpperCase())).sort((a: string, b: string) => {
        return KNOWN_CATEGORIES.indexOf(a.toUpperCase()) < KNOWN_CATEGORIES.indexOf(b.toUpperCase()) ? -1 : 1;
    })

    //detect change
    if (productCategories.indexOf('Services & Support') === -1 && addSupportLinks) {
        productCategories.push("Services & Support");
    }

    return <>{productCategories.map(category => getNav(category))}</>

}