import { createContext, PropsWithChildren, useContext, useState } from 'react';

interface ILoadingProviderMessage {
    message:string|undefined;
    hideBody?: boolean;
}

interface ILoadProvider {
    LoadingMessage: ILoadingProviderMessage;
    SetLoadingMessage: (message: ILoadingProviderMessage) => void;
}

export const LoadContext = createContext<ILoadProvider>({
    LoadingMessage: undefined,
    SetLoadingMessage: undefined
});

export const useLoadContext = () => useContext(LoadContext);

export function LoadProvider({ children }: PropsWithChildren<{}>) {
    const [loadingMessage, setLoadingMessage] = useState<ILoadingProviderMessage>(undefined);    
    return (
        <LoadContext.Provider value={{ LoadingMessage: loadingMessage, SetLoadingMessage: setLoadingMessage }}>
            {children}
        </LoadContext.Provider>
    );
}