import {
    DetailsList, DetailsListLayoutMode, IColumn, Icon, Link, MessageBar, MessageBarType, SelectionMode
} from '@fluentui/react';
import { getFileTypeIconProps, initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import saveAs from 'file-saver';
import * as React from 'react';
import { useState } from 'react';
import { apiPrefix } from '../lib/helper';
import { IWhitePaperFile } from '../pages/whitepapers';
import { useAuthenticationContext } from '../providers/authprovider';
import { makeApiCall } from '../services/apiService';

export const DetailsListWPFiles: React.FunctionComponent<{ whitePaperFileData: IWhitePaperFile[] }> = ({ whitePaperFileData }) => {

    initializeFileTypeIcons();
    const [error, setError] = useState<string>()
    const authContext = useAuthenticationContext()
    const downloadFile = (file: IWhitePaperFile) => {
        setError(undefined);
        if (authContext.Result?.IsAuthenticated) {
            makeApiCall(`${apiPrefix}getDocumentAsBlob?webServerRelativeUrl=&documentUrl=${file.FileRef}`).then(doc => {
                saveAs(doc, file.FileLeafRef);
            }).catch(err => {
                console.log("Error downloading file", err);
                setError(`Unable to download file`);
            })
        }
    }

    const wpListColumns: IColumn[] = [
        {
            key: 'column1', name: 'Name', fieldName: 'FileLeafRef', minWidth: 100, onRender: (item: IWhitePaperFile) => {
                return <><Icon className='detailsIconStyle' {...getFileTypeIconProps({ extension: item.FileLeafRef.substring(item.FileLeafRef.lastIndexOf(".") + 1), size: 24 })} />&nbsp;&nbsp;<Link onClick={(_ev) => {
                    downloadFile(item);
                }}>{item.FileLeafRef}</Link></>
            }
        },
        {
            key: 'column2', name: 'Published', fieldName: 'PublishedDate', minWidth: 100, isSortedDescending: true, onRender: (item: IWhitePaperFile) => {
                return <span>{item.PublishedDate ? new Intl.DateTimeFormat('en-us').format(new Date(item.PublishedDate)).toLocaleString() : ''}</span>
            }
        }
    ];

    //sort to support published date within groups
    const _sortWhitepapers = (a: IWhitePaperFile, b: IWhitePaperFile) => {
        if (a.Category === 'Archive' && b.Category !== 'Archive') return 1;
        if (a.Category !== 'Archive' && b.Category === 'Archive') return -1;

        const categoryComparison = a.Category.localeCompare(b.Category);
        if (categoryComparison !== 0) return categoryComparison;

        const subcategoryComparison = (a.Subcategory || '').localeCompare(b.Subcategory || '');
        if (subcategoryComparison !== 0) return subcategoryComparison;

        return (b.PublishedDate ? new Date(b.PublishedDate).getTime() : 0) - (a.PublishedDate ? new Date(a.PublishedDate).getTime() : 0);
    }

    //support sub categories in groups
    const _getCombinedCategory = (item: IWhitePaperFile) => {
        return item.Subcategory ? `${item.Category} - ${item.Subcategory}` : item.Category;
    }

    // Groups
    function getDocSets() {
        return whitePaperFileData.sort(_sortWhitepapers).map((item) => _getCombinedCategory(item))
            .filter((value, index, self) => self.indexOf(value) === index).map((r, idx) => {
                return { key: r, name: r, startIndex: whitePaperFileData.map(e => _getCombinedCategory(e)).indexOf(r), count: whitePaperFileData.filter((obj) => _getCombinedCategory(obj) === r).length, level: 0 }
            });
    }

    return (
        <div className='researchListFiles'>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <DetailsList
                items={[...whitePaperFileData].sort(_sortWhitepapers)}
                columns={wpListColumns}
                groups={getDocSets()}
                groupProps={{
                    showEmptyGroups: false,
                    isAllGroupsCollapsed: true
                }}
                compact={false}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
        </div>
    );

}