import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { DetailsListWPFiles } from '../elements/wpFileList';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

export interface IWhitePaperFile {
    Title: string;
    Category: string;
    Subcategory?: string;
    PublishedDate: string;
    FileRef: string;
    FileLeafRef: string;
    Modified: string;
}

const WhitePapers: React.FunctionComponent = () => {
    const loadContext = useLoadContext();
    const [error, setError] = useState<string>();
    const [whitePaperFileData, setWhitePaperFiles] = useState<IWhitePaperFile[]>();
    const authContext = useAuthenticationContext();

    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
            makeApiCall(`${apiPrefix}getWhitePaperFiles`).then((files) => {
                setWhitePaperFiles(files);
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? "You are not authorized to view this page" : "Error: See console for more information"; setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            })
        }
        else if (!authContext.Pending) {
            setError("You must be logged in to view this page")
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result]);

    return (
        <PageWrapper title='White Papers'>
            {!loadContext.LoadingMessage && <>
                {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                {!error && !whitePaperFileData && <MessageBar messageBarType={MessageBarType.info}>There doesn't seem to be any available content yet</MessageBar>}
                {!error && whitePaperFileData && <Stack tokens={{ childrenGap: 10 }}>
                    <DetailsListWPFiles whitePaperFileData={whitePaperFileData} />
                </Stack>}
            </>}

        </PageWrapper>
    );
};
export default WhitePapers